body {
  /*font-family: 'Roboto', sans-serif;*/
}


canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}



@media all {
  .page-break	{ display: none; }
}

@media print
{
  /*@page {size: letter portrait; margin:1mm;}*/
  /*html, body {height: 100%;}*/

  @page { margin: 1cm; }
  body { margin: 1.6cm; }

  .page-break	{ display: block; page-break-before: always; }

  /*body {*/
  /*  margin: 0;*/
  /*}*/

  .no-print, .no-print *
  {
    display: none !important;
  }

  .no-break {
    page-break-inside: avoid;
  }

  * {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
    color-adjust: exact !important;                 /*Firefox*/
    printer-colors: exact;
  }

  .pt-pr-15 {
    padding-top: 15rem!important;
  }

}

.list-line-height {
  line-height: 250%;
}

.vhr {
  border:none;
  height: 50px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 20px 20px -20px #333;
  margin: -50px auto 50px;
}

.vhr-top {
  border:none;
  height: 50px;
  border-top: 1px solid #ccc;
  box-shadow: 0 -20px 20px -20px #333;
  margin: 70px auto -10px;
}

.table-middle {
  vertical-align: middle;
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}
.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;
}

.full-circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 5px solid #4375a4;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.half-circle {
  width: 10px;
  height: 20px;
  /* background-color: gold; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 5px solid #28b7b2;
  border-left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.quarter-circle {
  width: 10px;
  height: 10px;
  /* background-color: gold; */
  border-top-right-radius: 10px;
  border: 5px solid #dc2229;
  border-left: 0;
  border-bottom: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pointer-cursor {
  cursor: pointer;
}

.table-hover thead tr:hover th, .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #e5f3ff;
}

.selected-response {
  background-color: rgba(232, 232, 232, .2);
}

.badge-custom {
  font-size: 9px;
  background: Firebrick;
  position: relative;
  -moz-transform: translate(-100%, -100%); /* For Firefox */
  -ms-transform: translate(-100%, -100%); /* for IE */
  -webkit-transform: translate(-100%, -100%); /* For Safari, Chrome, iOS */
  -o-transform: translate(-100%, -100%); /* For Opera */
}

/** BTN   ****/
.btn-outline-now:hover {
  color: #fff;
  background-color: tomato;
  border-color: tomato;
}


.btn-outline-now {
  font-size: 1.5em;
  color: tomato;
  border-color: tomato;
}

.btn-outline-forest {
  color: forestgreen;
  border-color: forestgreen;
}

.btn-outline-forest:hover {
  color: #fff;
  background-color: #084800;
  border-color: forestgreen;
}

.btn-now {
  font-size: 1.5em;
  color: #fff;
  background-color: tomato;
  border-color: tomato;
}

.btn-now:hover {
  color: #fff;
  background-color: #FF2305;
  border-color: #FF2305;
}

.btn-sign-in {
  font-size: 1.5em;
  color: #fff;
  background-color: forestgreen;
  border-color: forestgreen;
}

.btn-sign-in:hover {
  color: #fff;
  background-color: darkgreen;
  border-color: darkgreen;
}

.btn-ready {
  font-size: 1.2em;
  color: #fff;
  background-color: steelblue;
  border-color: steelblue;
}

.btn-ready:hover {
  color: #fff;
  background-color: dodgerblue;
  border-color: dodgerblue;
}

.btn-relate {
  font-size: 1.2em;
  color: #fff;
  background-color: olivedrab;
  border-color: olivedrab;
}

.btn-relate:hover {
  color: #fff;
  background-color: darkolivegreen;
  border-color: darkolivegreen;
}

.survey-no-answer {
  /*background-color: seashell;*/
  border: 0.2em solid lightcoral;
}

.used-code {
  color: #777;
  font-style: italic!important;
  font-size: 80%;
}

.grayscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

/*
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*/

/*
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

 */


/* Blue outline on focus */
* {
  box-sizing: border-box;
}


  .StripeElement {
    width: 100vw;
    max-width: 530px;
  }


@media (min-width: 480px) and (max-width: 825px) {

  .StripeElement {
    width: 90vw;
    max-width: 480px;
  }

}

@media (min-width: 321px) and (max-width: 479px) {

  .StripeElement {
    width: 85vw;
    max-width: 400px;
  }



}

@media  (max-width: 320px) {

  .StripeElement {
    width: 80vw;
  }

}




@media all and (max-width: 480px) {

  .pl {
    padding-left: 25vw;
  }

  .flex-custom-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .btn-now {
    font-size: 1.2rem;
    color: #fff;
    background-color: tomato;
    border-color: tomato;
  }

  .btn-now:hover {
    color: #fff;
    background-color: #FF2305;
    border-color: #FF2305;
  }

  .btn-sign-in {
    font-size: 1.2rem;
    color: #fff;
    background-color: forestgreen;
    border-color: forestgreen;
  }

  .btn-sign-in:hover {
    color: #fff;
    background-color: darkgreen;
    border-color: darkgreen;
  }
  .cmt-3 {
    margin-top: 1rem!important;
  }

  .img-responsive {
    width: 80px;
  }



}

@media all and (max-width: 512px) {

  .img-re-50 {
    display: none;
  }

  .grayscale {
    display: none;
  }

}


/*@media (min-width: 1281px) {*/

/*  .StripeElement {*/
/*    width: 45vw;*/
/*    max-width: 530px;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 950px) and (max-width: 1280px) {*/

/*  .StripeElement {*/
/*    width: 100vw;*/
/*    max-width: 530px;*/
/*  }*/

/*}*/

/*@media (min-width: 950px) and (max-width: 1280px) {*/

/*  .StripeElement {*/
/*    width: 33em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/


/*@media (min-width: 850px) and (max-width: 950px) {*/

/*  .StripeElement {*/
/*    width: 30em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 768px) and (max-width: 850px) {*/

/*  .StripeElement {*/
/*    width: 26em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {*/

/*  .StripeElement {*/
/*    width: 20em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 725px) and (max-width: 767px) {*/

/*  .StripeElement {*/
/*    width: 48em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 480px) and (max-width: 724px) {*/

/*  .StripeElement {*/
/*    width: 30em;*/
/*    !*width: 530px;*!*/
/*  }*/

/*}*/

/*@media (min-width: 395px) and (max-width: 479px) {*/

/*  .StripeElement {*/
/*    width: 25em;*/
/*  }*/

/*}*/

/*@media (min-width: 320px) and (max-width: 395px) {*/

/*  .StripeElement {*/
/*    width: 19em;*/
/*  }*/

/*}*/



/*@media (max-width: 320px) {*/

/*  .StripeElement {*/
/*    width: 13em;*/
/*  }*/

/*}*/


.StripeElement--focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}



.form-signin {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/*Custom styles for this template*/
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  max-width: 960px;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.btn.btn-outline-primary.btn-sm.mr-4.active {
  
}



/*
remove className from label and input to have a different look and feel
http://selfteach.me/customizing-radio-buttons-checkboxes/

input[type=radio] {
  display: none;
}

label {
  background: url('https://lh3.googleusercontent.com/CSygHWUdWSpIIfoPv3gwc0PctzOTVFGSCcSel4lOtxBBofmpt1QJxOF8MwrXbi0lIxxnKw2Cj6btMFTJZIGxWpkNpHZL41cOa5sgeSgtlsmat7YjkQlrU2XbmhPrxZwElMf9hucSAfEgF0VRLQy58ATRdf1mQULv5VcfnWFyus03KF8Egtg_pwW1_LRnklFEAUUZ_TyzEqFM3cp6G_aDyQpNCKxNvoLWkrcV-tgxqxQGAqC45JAkWWNXSxKgXD8TINX_xO1oml6fh_qVC32SwSQZqkk8Mkhvi2V3DSME0BzcS7wlku5tUK9ijkksro3Wp9kS7Ng4kyPDpkhZAh0kaiKXe4vUXfpZhhXuVjMwaP04HmMFJ7tjKt7bKvIHo0LcKv1zbbQsJ5ZTbI9wRabnUk9dWZtKE0w9BwiNbHuw1JrJBzmSCon9aQZD2DCD72DZEgaUchpdWKi-jShqHvrhaUYUeOUQiImDmo9NBKocQIW76p69uns8tbm1XXohE6tc-PKj8Cx66YtZLm4oUsvppee7Sw92GBFNvMPP9BGZSvxZmpV6TV5rNga1JBJOCMKw76SuYRNob9jCPmjzVMYjmFN8GknmaqOti7kh-QP7IlTUleyq=w25-h24-no') left top no-repeat;
  display: block;
  min-height: 25px;
  padding-left: 35px;
}

input[type=radio]:checked + label {
  background: url('https://lh3.googleusercontent.com/QSVgoBuOonsWKUZm6m0NKwekEvkfMOWrPFfevisISZjzeu2uQkxMOY1MUh3QgorRu2qc1kwKX4So7-mGJ-hayMAitq15L63KQNBfsOcm1qmWDU657CNl4lauYhgVz7f59IMrZBg8FWdgh26VDCvGMwUqaK6CBVv13AdKbxDMeDqiZS73EgzF5kBW5smh1nxCY5Gqk8yCmDsYh4KixdZWKGW7BqXLZn7cB2fQV4-o0DePjN69GJLXmddJ6H32CBgiFKE4smBvb1ByWpREmRYpFEUaHuQdbhHiiebI4Kipz35I6OxsVxKC6HOIQC3Xk6JVePhgudB2_-8Nyq2M9rWqIap8kAhmN-FI8iRdSLXhTV4FEeTHqokQ0qEXEZ5N9dRKoXT3al1Alcwrson_OtaALHldR9O7BO6Iq5_BV7-ERpoSYD8wGGhCcWQbjKkz5qHTa8yWRAfZaCLnK8Dzs0DPJtgMjQirittz8cpQQxQM4fhzXvG2x_Dtev4Y1YcZbzsaU6FpamC188SkjEDGOjYnf6CTk0VVbPk1DXFPIEGbOmc7b1unsq7uHOOkuRkGnTQ_d8aLNNPpeDvxP4agpI-ycfdH2ciG4sRXSKcdeAXGIHhNaI9T=w25-h24-no') left top no-repeat;
}

 */







/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: rgba(52,152,219, .67);
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}


.list-transition-enter, .list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: #5a564c;
}
/* This is where we can add the transition*/
.list-transition-enter-active, .list-transition-appear-active {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: #9e8949;
  transition: all 400ms;
}

.skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

width: 5.5em;
border-radius: 5px;

}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
/*****************************************************/

.hand {
  cursor: pointer;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll {
  max-height: 350px;
  overflow-y: auto;
}

.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);

}

.scroll::-webkit-scrollbar-thumb {

  -webkit-box-shadow: inset 0 0 15px rgba(0,0,0,0.5);
}

.romanticism {
  font-family: 'Nunito';
  font-size: 1em;
  border-radius: 20px;
  margin-top: -6em;
  padding-top: 7em;
  text-align: left;
}

.text-custom-blue {
  color: #4374a3;
}

.romanticism-gray {
  background-color: #eee;
}

.buffer
{
  margin: 1em;
  width: 200px;
}

.buffer .input-group input
{
  border-right: none;
}

.buffer .input-group .input-group-addon
{
  background: #fff;
  border-left: none;
}

.flex-even {
  flex: 1;
}

.flex-30 {
   flex: 30%;
 }

.flex-70 {
  flex: 70%;
}

.sum-border {
  border: 2px solid #4374a3;
}

/*@media (min-width: 768px) {*/
/*  .pt-md-20, .py-md-20 {*/
/*    padding-top: 15rem!important;*/
/*  }*/
/*}*/

.pl-6 {
  padding-left: 5rem!important;
}

.pr-6 {
  padding-right: 5rem!important;
}

.cover:hover
{
  background-color:#e0e9f2;
}

.gauge {
  font-weight: bold;
  font-family: 'Nunito';
  font-size: 1.5em;
  color: #4374a3;
}

.text-black-70 {
  color: rgba(0,0,0,.7)!important;
}

.selected-bg {
  background-color: #eee;
}

/*Bulk Pricing*/
ul.leaders {
  font-size: 1.2em;
  width: 100vm;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
}

ul.leaders li {
  padding-bottom: 10px;
}
ul.leaders li:after {
  float: left;

  width: 0;
  white-space: nowrap;
  content:
          ". . . . . . . . . . . . . . . . . . . . "
          ". . . . . . . . . . . . . . . . . . . . "
          ". . . . . . . . . . . . . . . . . . . . "
          ". . . . . . . . . . . . . . . . . . . . "
          ". . . . . . . . . . . . . . . . . . . . "
          ". . . . . . . . . . . . . . . . . . . . "}
ul.leaders span:first-child {
  padding-right: 0.33em;
  background: white}
ul.leaders span + span {
  float: right;
  padding-left: 0.33em;
  background: white;
  position: relative;
  z-index: 1}