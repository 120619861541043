.progressbar-r-container {
    width: 100%;
}

.progressbar-r {
    counter-reset: step;
    margin-left: -45px;

}

.progressbar-r li {
    list-style-type: none;
    float: left;
    width: 9%;
    position: relative;
    text-align: center;
    color: #ccc;
}

.progressbar-r li:before {
    content: counter(step);
    counter-increment: step;
    width: 50px;
    height: 50px;
    line-height: 48px;
    border: 2px solid #ccc;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}

.progressbar-r li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    top: 25px;
    left: -50%;
    z-index: -1;
}

.progressbar-r li:first-child:after {
    content: none;
}

.progressbar-r li.active {
    color: #28a745;
}

.progressbar-r li.active:before {
    border-color: #28a745;
    animation: shadow-pulse 2s infinite;

}

.progressbar-r li.active + li:after {
    background-color: #28a745;

}

.progressbar-r li.completed {
    color: #17a2b8;
}

.progressbar-r li.completed:before {
    border-color: #17a2b8;
    /*font-family: "FontAwesome";*/
    /*content: "\f007";*/
}

.progressbar-r li.completed + li:after {
    background-color: #17a2b8;
}

.progressbar-r li.sec-70287567:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f90e";
}

.progressbar-r li.sec-86976260:before {
     font-size: 1.4em;
     font-weight: lighter;
     font-family: "Font Awesome 5 Pro";
     content: "\f910";
 }


.progressbar-r li.sec-46712405:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f4c3";
}


.progressbar-r li.sec-87974638:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f47c";
}


.progressbar-r li.sec-1229403:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f4cb";
}

.progressbar-r li.sec-9676573:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f075";
}

.progressbar-r li.sec-54489379:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f06a";
}

.progressbar-r li.sec-17106818:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f3e0";
}

.progressbar-r li.sec-79532769:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f942";
}

.progressbar-r li.sec-26791334:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f93e";
}

.progressbar-r li.sec-32849784:before {
    font-size: 1.4em;
    font-weight: lighter;
    font-family: "Font Awesome 5 Pro";
    content: "\f2c2";
}


@keyframes shadow-pulse
{
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}

/*.progressbar li:before  {*/
/*  animation: shadow-pulse 1s infinite;*/
/*}*/