.progressbar-container {
  width: 100%;
}

.progressbar {
  counter-reset: step;
  margin-left: -45px;

}

.progressbar li {
  list-style-type: none;
  float: left;
  width: 10%;
  position: relative;
  text-align: center;
  color: #ccc;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 2px solid #ccc;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}

.progressbar li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  top: 25px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.active {
  color: #28a745;
}

.progressbar li.active:before {
  border-color: #28a745;
  animation: shadow-pulse 2s infinite;

}

.progressbar li.active + li:after {
  background-color: #28a745;

}

.progressbar li.completed {
  color: #17a2b8;
}

.progressbar li.completed:before {
  border-color: #17a2b8;
  /*font-family: "FontAwesome";*/
  /*content: "\f007";*/
}

.progressbar li.completed + li:after {
  background-color: #17a2b8;
}

.progressbar li.sec-40258092:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4c3";
}

.progressbar li.sec-14367796:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4be";
}

.progressbar li.sec-11964156:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4c9";
}

.progressbar li.sec-52270581:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4c6";
}

.progressbar li.sec-74154809:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4bc";
}

.progressbar li.sec-13236308:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f5a2";
}

.progressbar li.sec-97139865:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f4cb";
}

.progressbar li.sec-24150198:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f075";
}
.progressbar li.sec-41774674:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f06a";
}

.progressbar li.sec-68548238:before {
  font-size: 1.4em;
  font-weight: lighter;
  font-family: "Font Awesome 5 Pro";
  content: "\f2c2";
}

@keyframes shadow-pulse
{
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

/*.progressbar li:before  {*/
/*  animation: shadow-pulse 1s infinite;*/
/*}*/