#bulk > .StripeElement {
    width: 100vw;
    max-width: 570px;
}

@media (min-width: 480px) and (max-width: 825px) {

    #bulk > .StripeElement {
        width: 90vw;
        max-width: 480px;
    }

}

@media (min-width: 321px) and (max-width: 479px) {

    #bulk > .StripeElement {
        width: 85vw;
        max-width: 400px;
    }



}

@media  (max-width: 320px) {

    #bulk > .StripeElement {
        width: 80vw;
    }

}

.bulkOrderLabel {
    font-size: 1.2em;
    font-weight: bolder;
}